$('.mobile-controls .btn').click(function(){
	var topo = document.querySelector('.topo');
	var bgMenu = document.createElement('div');

	$(bgMenu).addClass('bg-menu');

	$(bgMenu).click(function(){
		$('.content-topo').removeClass('open');
		$('body, html').removeClass('lock-scroll');

		$('.bg-menu').animate({
			opacity: 0
		}, 600, function(){
			$('.bg-menu').remove();
		});
	});

	topo.appendChild(bgMenu);

	$(bgMenu).fadeIn(600);

	$('.content-topo').addClass('open');
	$('body,html').addClass('lock-scroll');
	$(this).blur();
});